import { Button } from "react-bootstrap";
import { AiFillCheckCircle } from "react-icons/ai";

import Swal from "sweetalert2";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ASSET_URL } from "../../constant";
import {
  BackButton,
  FileUpload,
  Form,
  Input,
  TextArea,
} from "../../Components";
import { uploadPublic } from "../../Services/FileUploadService";
import {
  createSiteManagement,
  getSiteManagement,
  updateSiteManagement,
} from "../../Services/Service";
import { onFailure } from "../../util/helper";
import { Site_Management } from "../../RouteConstant";

export const SiteManagementPage = () => {
  const [initialValues, setInitialValues] = useState<any>({
    siteName: "",
    seo: {
      title: "",
      description: "",
    },
  });
  const history = useHistory();
  useEffect(() => {
    if (localStorage.getItem("_id")) {
      getSiteManagement(onGetSuccess, onFailure);
    }
  }, []);
  const onGetSuccess = (res: any) => {
    setInitialValues(res.data);
  };
  const onSuccess = (res: any) => {
    Swal.fire({
      title: initialValues._id
        ? "Site Detail Updated Successfully"
        : "Site Detail added Successfully",
      icon: "success",
    }).then(() => {
      history.push(Site_Management);
    });
  };
  const onSubmit = (values: any) => {
    if (initialValues._id) {
      values._id = initialValues._id;
      updateSiteManagement(values, onSuccess, onFailure);
    } else {
      createSiteManagement(values, onSuccess, onFailure);
    }
  };
  return (
    <>
      <main className="app-content" id="app">
        <BackButton />
        <div className="tile">
          <div>
            <div className="app-title"></div>
            <div className="row">
              <div className="col-md-12">
                <Form
                  initialValues={initialValues}
                  render={({ handleChange, values }: any) => {
                    return (
                      <>
                        <div className="row">
                          <div className="form-group">
                            <div className="col-md-12">
                              <div className="row ">
                                <div className="col-md-12 p-2">
                                  <Input
                                    type="text"
                                    name="siteName"
                                    label="Site Name"
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                                <div className="col-md-6 p-2">
                                  <FileUpload
                                    accept={[
                                      "image/png",
                                      "image/jpeg",
                                      "image/webp",
                                      "image/svg",
                                    ]}
                                    type="single"
                                    maxSize={"3mb"}
                                    name={"siteLogo"}
                                    upload={uploadPublic}
                                    assetUrl={ASSET_URL}
                                    label="Site Logo"
                                  />
                                </div>
                                <div className="col-md-6 p-2">
                                  <FileUpload
                                    accept={[
                                      "image/png",
                                      "image/jpeg",
                                      "image/webp",
                                      "image/svg",
                                    ]}
                                    type="single"
                                    maxSize={"3mb"}
                                    name={"siteFavicon"}
                                    upload={uploadPublic}
                                    assetUrl={ASSET_URL}
                                    label="Site Favicon"
                                  />
                                </div>
                                <div className="col-md-6 p-2">
                                  <FileUpload
                                    accept={["application/xml", "text/xml"]}
                                    type="single"
                                    maxSize={"3mb"}
                                    name={"sitemap"}
                                    upload={uploadPublic}
                                    assetUrl={ASSET_URL}
                                    label="Sitemap"
                                  />
                                </div>
                                <div className="col-md-6 p-2">
                                  <FileUpload
                                    accept={["text/plain"]}
                                    type="single"
                                    maxSize={"3mb"}
                                    name={"robotFile"}
                                    upload={uploadPublic}
                                    assetUrl={ASSET_URL}
                                    label="Robots file"
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <TextArea
                                    id="google_analytics"
                                    name={`googleAnalytics`}
                                    label="Google Analytics"
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <TextArea
                                    id="facebook_analytics"
                                    name={`facebookAnalytics`}
                                    label="Facebook Analytics"
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <Input
                                    type="text"
                                    name="footerCopyright"
                                    label="Footer Copyright Text"
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <Input
                                    type="text"
                                    name="facebookProfile"
                                    label="Facebook Profile"
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <Input
                                    type="text"
                                    name="youtubeProfile"
                                    label="Youtube Profile"
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <Input
                                    type="text"
                                    name="twitterProfile"
                                    label="Twitter Profile"
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <Input
                                    type="text"
                                    name="instagramProfile"
                                    label="Instagram Profile"
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <Input
                                    type="text"
                                    name="linkedInProfile"
                                    label="LinkedIn Profile"
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <Input
                                    type="text"
                                    name="seo.title"
                                    label="SEO TITLE"
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <Input
                                    type="text"
                                    name="seo.description"
                                    label="SEO DESCRIPTION"
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                                <div className="col-md-12 p-2">
                                  <Input
                                    name="seo.metaTags[0].content"
                                    id="seo.metaTags[0].content"
                                    label="Keywords (500 character limit)"
                                    maxLength="500"
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end uvs p-2">
                          <Button
                            className="btn btn-success mr-2"
                            type="button"
                            onClick={() => window.history.go(-1)}
                          >
                            <AiFillCheckCircle />
                            Cancel
                          </Button>

                          <Button
                            className="btn btn-success mr-2"
                            type="submit"
                            onClick={() => onSubmit(values)}
                          >
                            <AiFillCheckCircle />
                            Save
                          </Button>
                        </div>
                      </>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

import * as routeKeys from "./constant";
import * as routePaths from "./RouteConstant";
import DashboardPage from "./Page/DashboardPage";
import CreateUserPage from "./Page/User/CreateUserPage";
import ManageCategoryPage from "./Page/Category/ManageCategoryPage";
// import CreateCategoryPage from "./Page/Category/CreateCategory";
import LoginPage from "./Page/LoginPage";
import ManageUserPage from "./Page/User/ManageUserPage";
import MenuManagementPage from "./Page/Appearance/Menu/MenuManagement";
// import PageManagementPage from "./Page/Appearance/Page/PageManagementPage";
import CreatePage from "./Page/Appearance/Page/CreatePage,";
import ProfilePage from "./Page/ProfilePage";
import ChangePasswordPage from "./Page/ChangePasswordPage";
import EditMenu from "./Page/Appearance/Menu/EditMenu";
import MangageEpaper from "./Page/NewsManagement/Epaper/Manage";
import CreateEpaper from "./Page/NewsManagement/Epaper/Create";
import CreateRolePage from "./Page/Role/CreateRolePage";
import ManageRolePage from "./Page/Role/ManageRolePage";
import {
  CreateArticle,
  CreateVideo,
  CreateGallery,
  BlogManagementPage,
} from "./Page/NewsManagement/Blog";
import EditUserPage from "./Page/User/EditUserPage";
import ChangeUserPassword from "./Page/User/ChangeUserPassword";
import TopHeadline from "./Page/Appearance/HomePage/TopHeadline";
import TopStory from "./Page/Appearance/HomePage/TopStory";
import ManageSection from "./Page/Appearance/HomePage/ManageSection";
import CoverStory from "./Page/Appearance/HomePage/CoverStory";
import ManageClient from "./Page/Advertisement/Client/Manage";
import CreateClient from "./Page/Advertisement/Client/Create";
import MangageAdvertisement from "./Page/Advertisement/Ad/Manage";
import { CreateAdvertisement } from "./Page/Advertisement/Ad/Create";
import ManageBreakingNewsPage from "./Page/BreakingNews/ManageBreakingNewsPage";
import { SiteManagementPage } from "./Page/SiteManagement/SiteManagement";

export const routes = [
  {
    key: routeKeys.ADMIN,
    path: routePaths.Login,
    component: LoginPage,
  },
  {
    key: routeKeys.CREATE_USER,
    path: routePaths.Create_User,
    component: CreateUserPage,
    admin: true,
    props: {
      resource: routeKeys.PERMISSION_ENUM.User,
      action: [routeKeys.PERMISSION_ACCESS_ENUM.Write],
    },
  },
  {
    key: routeKeys.EDIT_USER,
    path: routePaths.Edit_User,
    component: EditUserPage,
    admin: true,
    props: {
      resource: routeKeys.PERMISSION_ENUM.User,
      action: [routeKeys.PERMISSION_ACCESS_ENUM.Edit],
    },
  },
  {
    key: routeKeys.CHANGE_USER_PASSWORD,
    path: routePaths.Change_User_Password,
    component: ChangeUserPassword,
    admin: true,
    props: {
      resource: routeKeys.PERMISSION_ENUM.User,
      action: [routeKeys.PERMISSION_ACCESS_ENUM.Edit],
    },
  },
  {
    key: routeKeys.MANAGE_USER,
    path: routePaths.Manage_User,
    component: ManageUserPage,
    admin: true,
    props: {
      resource: routeKeys.PERMISSION_ENUM.User,
      action: [routeKeys.PERMISSION_ACCESS_ENUM.Edit],
    },
  },
  {
    key: routeKeys.CREATE_ROLE,
    path: routePaths.Create_Role,
    component: CreateRolePage,
    admin: true,
    props: {
      resource: routeKeys.PERMISSION_ENUM.Role,
      action: [routeKeys.PERMISSION_ACCESS_ENUM.Write],
    },
  },
  {
    key: routeKeys.EDIT_ROLE,
    path: routePaths.Edit_Role,
    component: CreateRolePage,
    admin: true,
    props: {
      resource: routeKeys.PERMISSION_ENUM.Role,
      action: [routeKeys.PERMISSION_ACCESS_ENUM.Edit],
    },
  },
  {
    key: routeKeys.MANAGE_ROLE,
    path: routePaths.Manage_Role,
    component: ManageRolePage,
    admin: true,
    props: {
      resource: routeKeys.PERMISSION_ENUM.Role,
      action: [routeKeys.PERMISSION_ACCESS_ENUM.Read],
    },
  },

  {
    key: routeKeys.MANAGE_CATEGORY,
    path: routePaths.Manage_Category,
    component: ManageCategoryPage,
    admin: true,
    props: {
      resource: routeKeys.PERMISSION_ENUM.Category,
      action: [routeKeys.PERMISSION_ACCESS_ENUM.Read],
    },
  },
  {
    key: routeKeys.MANAGE_BREAKING_NEWS,
    path: routePaths.Manage_Breaking_News,
    component: ManageBreakingNewsPage,
    admin: true,
    props: {
      resource: routeKeys.PERMISSION_ENUM.BreakingNews,
      action: [routeKeys.PERMISSION_ACCESS_ENUM.Read],
    },
  },

  {
    key: routeKeys.CREATE_PAGE,
    path: routePaths.Create_Page,
    component: CreatePage,
    admin: true,
    props: {
      resource: routeKeys.PERMISSION_ENUM.Cms,
      action: [routeKeys.PERMISSION_ACCESS_ENUM.Write],
    },
  },
  // {
  //   key: routeKeys.EDIT_PAGE,
  //   path: routePaths.Edit_Page,
  //   component: CreatePage,
  //   admin: true,
  // },
  {
    key: routeKeys.MENU_MANAGEMENT,
    path: routePaths.Menu_Management,
    component: MenuManagementPage,
    admin: true,
    props: {
      resource: routeKeys.PERMISSION_ENUM.Menu,
      action: [routeKeys.PERMISSION_ACCESS_ENUM.Read],
    },
  },
  {
    key: routeKeys.CREATE_MENU,
    path: routePaths.Create_Menu,
    component: EditMenu,
    admin: true,
    props: {
      resource: routeKeys.PERMISSION_ENUM.Menu,
      action: [routeKeys.PERMISSION_ACCESS_ENUM.Write],
    },
  },
  {
    key: routeKeys.EDIT_MENU,
    path: routePaths.Edit_Menu,
    component: EditMenu,
    admin: true,
    props: {
      resource: routeKeys.PERMISSION_ENUM.Menu,
      action: [routeKeys.PERMISSION_ACCESS_ENUM.Edit],
    },
  },
  {
    key: routeKeys.CREATE_ARTICLE,
    path: routePaths.Create_Article,
    component: CreateArticle,
    admin: true,
    props: {
      resource: routeKeys.PERMISSION_ENUM.Cms,
      action: [routeKeys.PERMISSION_ACCESS_ENUM.Write],
    },
  },
  {
    key: routeKeys.CREATE_VIDEO_ARTICLE,
    path: routePaths.Create_Video_Article,
    component: CreateVideo,
    admin: true,
    props: {
      resource: routeKeys.PERMISSION_ENUM.Cms,
      action: [routeKeys.PERMISSION_ACCESS_ENUM.Write],
    },
  },
  {
    key: routeKeys.CREATE_GALLERY_ARTICLE,
    path: routePaths.Create_Gallery_Article,
    component: CreateGallery,
    admin: true,
    props: {
      resource: routeKeys.PERMISSION_ENUM.Cms,
      action: [routeKeys.PERMISSION_ACCESS_ENUM.Write],
    },
  },
  {
    key: routeKeys.EDIT_VIDEO_ARTICLE,
    path: routePaths.Edit_Video_Article,
    component: CreateVideo,
    admin: true,
    props: {
      resource: routeKeys.PERMISSION_ENUM.Cms,
      action: [routeKeys.PERMISSION_ACCESS_ENUM.Edit],
    },
  },
  {
    key: routeKeys.EDIT_GALLERY_ARTICLE,
    path: routePaths.Edit_Gallery_Article,
    component: CreateGallery,
    admin: true,
    props: {
      resource: routeKeys.PERMISSION_ENUM.Cms,
      action: [routeKeys.PERMISSION_ACCESS_ENUM.Edit],
    },
  },
  {
    key: routeKeys.EDIT_ARTICLE,
    path: routePaths.Edit_Article,
    component: CreateArticle,
    admin: true,
    props: {
      resource: routeKeys.PERMISSION_ENUM.Cms,
      action: [
        routeKeys.PERMISSION_ACCESS_ENUM.Edit,
        routeKeys.PERMISSION_ACCESS_ENUM.EditOwn,
      ],
    },
  },
  {
    key: routeKeys.ARTICLE_MANAGEMENT,
    path: routePaths.Article_Management,
    component: BlogManagementPage,
    admin: true,
    props: {
      resource: routeKeys.PERMISSION_ENUM.Cms,
      action: [routeKeys.PERMISSION_ACCESS_ENUM.Read],
    },
  },
  {
    key: routeKeys.CREATE_EPAPER,
    path: routePaths.Create_Epaper,
    component: CreateEpaper,
    admin: true,
    props: {
      resource: routeKeys.PERMISSION_ENUM.Epaper,
      action: [routeKeys.PERMISSION_ACCESS_ENUM.Write],
    },
  },
  {
    key: routeKeys.EDIT_EPAPER,
    path: routePaths.Edit_Epaper,
    component: CreateEpaper,
    admin: true,
    props: {
      resource: routeKeys.PERMISSION_ENUM.Epaper,
      action: [
        routeKeys.PERMISSION_ACCESS_ENUM.Edit,
        routeKeys.PERMISSION_ACCESS_ENUM.EditOwn,
      ],
    },
  },
  {
    key: routeKeys.MANAGE_EPAPER,
    path: routePaths.Epaper_Management,
    component: MangageEpaper,
    admin: true,
    props: {
      resource: routeKeys.PERMISSION_ENUM.Epaper,
      action: [routeKeys.PERMISSION_ACCESS_ENUM.Read],
    },
  },
  {
    key: routeKeys.CREATE_AD,
    path: routePaths.Create_Ad,
    component: CreateAdvertisement,
    admin: true,
    props: {
      resource: routeKeys.PERMISSION_ENUM.Advertisement,
      action: [routeKeys.PERMISSION_ACCESS_ENUM.Write],
    },
  },
  {
    key: routeKeys.EDIT_AD,
    path: routePaths.Edit_Ad,
    component: CreateAdvertisement,
    admin: true,
    props: {
      resource: routeKeys.PERMISSION_ENUM.Advertisement,
      action: [routeKeys.PERMISSION_ACCESS_ENUM.Edit],
    },
  },
  {
    key: routeKeys.MANAGE_AD,
    path: routePaths.Ad_Management,
    component: MangageAdvertisement,
    admin: true,
    props: {
      resource: routeKeys.PERMISSION_ENUM.Advertisement,
      action: [routeKeys.PERMISSION_ACCESS_ENUM.Read],
    },
  },
  {
    key: routeKeys.CREATE_CLIENT,
    path: routePaths.Create_Client,
    component: CreateClient,
    admin: true,
    props: {
      resource: routeKeys.PERMISSION_ENUM.Client,
      action: [routeKeys.PERMISSION_ACCESS_ENUM.Write],
    },
  },
  {
    key: routeKeys.EDIT_CLIENT,
    path: routePaths.Edit_Client,
    component: CreateClient,
    admin: true,
    props: {
      resource: routeKeys.PERMISSION_ENUM.Client,
      action: [
        routeKeys.PERMISSION_ACCESS_ENUM.Edit,
        routeKeys.PERMISSION_ACCESS_ENUM.EditOwn,
      ],
    },
  },
  {
    key: routeKeys.MANAGE_CLIENT,
    path: routePaths.Client_Management,
    component: ManageClient,
    admin: true,
    props: {
      resource: routeKeys.PERMISSION_ENUM.Client,
      action: [routeKeys.PERMISSION_ACCESS_ENUM.Read],
    },
  },
  {
    key: routeKeys.HOME_TOP_HEADLINE,
    path: routePaths.Home_Top_Headline,
    component: TopHeadline,
    admin: true,
  },
  {
    key: routeKeys.HOME_COVER_STORY,
    path: routePaths.Home_Cover_Story,
    component: CoverStory,
    admin: true,
  },
  {
    key: routeKeys.HOME_TOP_STORY,
    path: routePaths.Home_Top_Story,
    component: TopStory,
    admin: true,
  },
  {
    key: routeKeys.HOME_MANAGE_SECTION,
    path: routePaths.Home_Manage_Section,
    component: ManageSection,
    admin: true,
  },
  // {
  //   key: routeKeys.PAGE_MANAGEMENT,
  //   path: routePaths.Page_Management,
  //   component: PageManagementPage,
  //   admin: true,
  // },
  {
    key: routeKeys.DASHBOARD,
    path: routePaths.Dashboard,
    component: DashboardPage,
    admin: true,
  },
  {
    key: routeKeys.SETTINGS,
    path: routePaths.Settings,
    component: DashboardPage,
    admin: true,
  },
  {
    key: routeKeys.PROFILE,
    path: routePaths.Profile,
    component: ProfilePage,
    admin: true,
  },
  {
    key: routeKeys.CHANGE_PASSWORD,
    path: routePaths.Change_Password,
    component: ChangePasswordPage,
    admin: true,
  },
  {
    key: routeKeys.SITE_MANAGEMENT,
    path: routePaths.Site_Management,
    component: SiteManagementPage,
    admin: true,
  },
];

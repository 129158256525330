import { HTML, MetaTags } from "./interfaces";

export const HOME = "HOME";
export const ADMIN = "ADMIN";
export const DASHBOARD = "DASHBOARD";
export const SETTINGS = "SETTINGS";
export const PROFILE = "PROFILE";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const MANAGE_ADMIN = "MANAGE_ADMIN";
export const ADMIN_PAGE = "ADMIN_PAGE";
export const MANAGE_CATEGORY = "MANAGE_CATEGORY";
export const MANAGE_USER = "MANAGE_USER";
export const CREATE_USER = "CREATE_USER";
export const EDIT_USER = "EDIT_USER";
export const CHANGE_USER_PASSWORD = "CHANGE_USER_PASSWORD";
export const MANAGE_ROLE = "MANAGE_ROLE";
export const CREATE_ROLE = "CREATE_ROLE";
export const EDIT_ROLE = "EDIT_ROLE";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const EDIT_CATEGORY = "EDIT_CATEGORY";
export const PAGE_MANAGEMENT = "PAGE_MANAGEMENT";
export const ARTICLE_MANAGEMENT = "ARTICLE_MANAGEMENT";
export const MENU_MANAGEMENT = "MENU_MANAGEMENT";
export const CREATE_MENU = "CREATE_MENU";
export const EDIT_MENU = "EDIT_MENU";
export const CREATE_ARTICLE = "CREATE_ARTICLE";
export const CREATE_GALLERY_ARTICLE = "CREATE_GALLERY_ARTICLE";
export const CREATE_VIDEO_ARTICLE = "CREATE_VIDEO_ARTICLE";
export const EDIT_ARTICLE = "EDIT_ARTICLE";
export const EDIT_GALLERY_ARTICLE = "EDIT_GALLERY_ARTICLE";
export const EDIT_VIDEO_ARTICLE = "EDIT_VIDEO_ARTICLE";
export const EDIT_EPAPER = "EDIT_EPAPER";
export const CREATE_EPAPER = "CREATE_EPAPER";
export const MANAGE_EPAPER = "MANAGE_EPAPER";
export const CREATE_AD = "CREATE_AD";
export const EDIT_AD = "EDIT_AD";
export const MANAGE_AD = "MANAGE_AD";
export const CREATE_CLIENT = "CREATE_CLIENT";
export const EDIT_CLIENT = "EDIT_CLIENT";
export const MANAGE_CLIENT = "MANAGE_CLIENT";
export const CREATE_PAGE = "CREATE_PAGE";
export const HOME_TOP_HEADLINE = "HOME_TOP_HEADLINE";
export const HOME_COVER_STORY = "HOME_COVER_STORY";
export const HOME_TOP_STORY = "HOME_TOP_STORY";
export const HOME_MANAGE_SECTION = "HOME_MANGE_SECTION";
export const EDIT_PAGE = "EDIT_PAGE";
let BASE_URL = "";
BASE_URL = "https://api.lexiconfitnessacademy.com/api/v1";

// BASE_URL = "http://localhost:4444/api/v1";
export const MANAGE_BREAKING_NEWS = "MANAGE_BREAKING_NEWS";
export const SITE_MANAGEMENT = "SITE_MANAGEMENT";
export const SITE_URL = "http://blog.lipijobs.com";
export let ASSET_URL = "";

if (process.env.REACT_APP_ENV === "local") {
  BASE_URL = "http://localhost:8888/api/v1";
  ASSET_URL = "https://lipijobs-blog.s3.ap-south-1.amazonaws.com/full";
}
if (process.env.REACT_APP_ENV === "lexi") {
  BASE_URL = "https://api.lexiconfitnessacademy.com/api/v1";
  ASSET_URL = "https://lexicon-fitnessacademy.s3.ap-south-1.amazonaws.com/full";
}
if (process.env.REACT_APP_ENV === "ihm") {
  BASE_URL = "https://api.lexiconihm.com/api/v1";
  ASSET_URL = "https://lexicon-ihm.s3.ap-south-1.amazonaws.com/full";
}
if (process.env.REACT_APP_ENV === "lipi") {
  BASE_URL = "https://blog.api.lipijobs.com/api/v1";
  ASSET_URL = "https://lipijobs-blog.s3.ap-south-1.amazonaws.com/full";
}
if (process.env.REACT_APP_ENV === "crli") {
  BASE_URL = "https://api.crli.lexiconmile.com/api/v1";
  ASSET_URL = "https://lexicon-mile-crli-blog.s3.ap-south-1.amazonaws.com/full";
}
if (process.env.REACT_APP_ENV === "mile") {
  BASE_URL = "https://api.lexiconmile.com/api/v1";
  ASSET_URL = "https://lexicon-mile-blog.s3.ap-south-1.amazonaws.com/full";
}
if (process.env.REACT_APP_ENV === "ima") {
  BASE_URL = "https://api.lexiconima.com/api/v1";
  ASSET_URL = "https://lexicon-ima-blog.s3.ap-south-1.amazonaws.com/full";
}
if (process.env.REACT_APP_ENV === "kids") {
  BASE_URL = "https://api.lexiconkids.com/api/v1";
  ASSET_URL = "https://lexicon-kids-blog.s3.ap-south-1.amazonaws.com/full";
}
if (process.env.REACT_APP_ENV === "rainbow") {
  BASE_URL = "https://api.lexiconrainbow.com/api/v1";
  ASSET_URL = "https://lexicon-rainbow-blog.s3.ap-south-1.amazonaws.com/full";
}
if (process.env.REACT_APP_ENV === "multifit") {
  BASE_URL = "https://api.blog.multifit.in/api/v1";
  ASSET_URL = "https://multifit-blog.s3.ap-south-1.amazonaws.in/full";
}

export const SMALL_PAGE_SIZE = 10;
export const HTML_FIELDS: HTML = {
  title: "",
  body: "",
};
export const META_TAGS: MetaTags = {
  content: "",
  name: "",
};
export enum PERMISSION_ENUM {
  User = "User",
  Role = "Role",
  Asset = "Asset",
  Category = "Category",
  Cms = "Cms",
  Menu = "Menu",
  Epaper = "Epaper",
  Advertisement = "Advertisement",
  Client = "Client",
  BreakingNews = "BreakingNews",
}
export const PERMISSION_MODULE: any = {
  User: "User",
  Role: "Roles and Permissons",
  Asset: "Media",
  Category: "Categories",
  Cms: "Article",
  Menu: "Menu",
  Epaper: "Epaper",
};

export enum PERMISSION_ACCESS_ENUM {
  Read = "Read",
  Write = "Write",
  Edit = "Edit",
  EditOwn = "Edit:own",
  Remove = "Remove",
}

export const PERMISSION_ACTION: string[] = [
  "Read",
  "Write",
  "Edit",
  "Edit:own",
];

export const TEMPLATE_OPTIONS = [
  {
    label: "Cards",
    value: "template-1",
  },
  {
    label: "News Columns",
    value: "template-2",
  },
  {
    label: "Sports Columns",
    value: "template-3",
  },
  {
    label: "Box+List",
    value: "template-4",
  },
  {
    label: "Box+Text",
    value: "template-5",
  },
  {
    label: "Lists",
    value: "template-6",
  },
];
export const MENU_OPTIONS = [
  {
    label: "Top Menu",
    value: "menu-1",
  },
  {
    label: "Footer Menu",
    value: "menu-2",
  },
];

export { BASE_URL };

export const Dashboard = "/";
export const Login = "/login";
export const Manage_User = "/user";
export const Create_User = "/user/create";
export const Edit_User = "/user/edit/:id";
export const Change_User_Password = "/user/change-password/:id";
export const Manage_Role = "/role";
export const Create_Role = "/role/create";
export const Edit_Role = "/role/edit/:id";
export const Manage_Category = "/shop/catagory";
export const Create_Category = "/shop/category/create";
export const Menu_Management = "/menu";
export const Create_Menu = "/create";
export const Edit_Menu = "/menu/:id";
export const Article_Management = "/article";
export const Create_Article = "/article/create";
export const Create_Video_Article = "/article/video/create";
export const Create_Gallery_Article = "/article/gallery/create";
export const Edit_Article = "/article/edit/:id";
export const Edit_Video_Article = "/article/video/edit/:id";
export const Edit_Gallery_Article = "/article/gallery/edit/:id";
export const Epaper_Management = "/epaper";
export const Create_Epaper = "/epaper/create";
export const Edit_Epaper = "/epaper/edit/:id";
export const Ad_Management = "/advertisement";
export const Create_Ad = "/advertisement/create";
export const Edit_Ad = "/advertisement/edit/:id";
export const Client_Management = "/client";
export const Create_Client = "/client/create";
export const Edit_Client = "/client/edit/:id";
export const Home_Top_Headline = "/home/top-headline";
export const Home_Cover_Story = "/home/cover-story";
export const Home_Top_Story = "/home/top-story";
export const Home_Manage_Section = "/home/manage-section";
export const Page_Management = "/page";
export const Create_Page = "/page/create";
export const Edit_Page = "/page/edit/:id";
export const Settings = "/settings";
export const Profile = "/profile";
export const Change_Password = "/change-password";
export const Manage_Breaking_News = "/breraking-news";
export const Site_Management = "/site-management";
